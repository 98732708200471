<template>
  <div class="message-fixed">
    <div class="message-box" :class="!show && 'hideMessage'" :style="';background:'+((type == 'success' && '#f0f9eb') || (type == 'warning' && '#fdf6ec') || (type == 'danger' && '#fef0f0'))+';color:'+((type == 'success' && '#67c23a') || (type == 'warning' && '#e6a23c') || (type == 'danger' && '#f56c6c'))+';border-color:'+((type == 'success' && '#67c23a') || (type == 'warning' && '#e6a23c') || (type == 'danger' && '#f56c6c'))">{{msg}}</div>
  </div>
</template>

<script>
export default {
  props: {
    msg: {
      type: String,
      default: "消息！消息！"
    },
    show: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: "success"
    }
  },
}
</script>

<style lang="scss" scoped>
.message-fixed{
  position: fixed;
  top: 0;
  height: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
}
.message-box{
  width: 500px;
  margin: 0 auto;
  margin-top: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  transition: all .3s;
}
.hideMessage{
  margin-top: -40px;
  opacity: 0;
  transform: scaleX(.5);
}
</style>